@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
:root {
    --text-black: #3F3F3F;
    --text-grey: #8F9AA5;
    --white: #ffffff;
    --primary: #091D48;
    --dark-purple: #091D48;
    --grey: #808080;
    --elephant-white: #DEDEE5;
    --light-grey: #ECEEF0;
    --light-grey: #004e97;
    --border: #DEE1E4;   
    --background: #d2daff;



}

.contact-heading{
    font-size: 68px;
    color: var(--dark-purple);
    font-weight: bold;
}
.contact-sub-heading{
    font-size: 31px;
    font-weight: bold;
    color: var(--text-black);
}
.contact-img{
    object-fit: contain;
}
.contact-img>img{
    height: 80%;
    width: 100%;
}

.form-label{
    font-weight: bold;
    color: var(--dark-purple);
}
.form-control{
    display: block;
    width: 100%;
    padding: .5rem 0.3rem;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--grey);
    appearance: none;
    background-color: transparent;
    /* background-clip: padding-box; */
    border: none;
    border-bottom: 1px solid var(--dark-purple);
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]{
    -moz-appearance: textfield;
}


/* input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
.uneditable-input:focus {   
  border-color: rgba(126, 239, 104, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
} */


.form-control:focus {
    border-color: var(--dark-purple);
    /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 255, 255, 0.6); */
    background-color: transparent;
    /* border: none; */
    box-shadow: none;
}
.contact-btn{
    background-color: var(--dark-purple) !important;
    border: none;
    font-weight: bold;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.contact-btn:hover{
    scale: 1.01;
    box-shadow: 4px 4px 10px 2px rgba(180, 72, 207, 0.5);
    transition: all ease-in .3s;

}
@media screen and (min-width:520px) and (max-width:1080px) {
    .contact-heading {
        font-size: 40px;
    }
    .contact-sub-heading{
        font-size: 28px;
    }
}
@media screen and (max-width:991px){
    .contact-img>img{
        height: 80%;
        width: 60%;
    }
}
@media screen and (max-width:680px){
    .contact-img>img{
        height: 90%;
        width: 70%;
    }
}
@media screen and (max-width:575px){
    .contact-heading{
        font-size: 30px;
    }
    .contact-sub-heading{
        font-size: 20px;
    }
    
}
@media screen and (max-width:360px){
    .contact-img>img{
        height: 100%;
        width: 100%;
    }
}
