@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
:root {
  --text-black: #3f3f3f;
  --text-grey: #8f9aa5;
  --white: #ffffff;
  --primary: #091d48;
  --dark-purple: #091d48;
  --grey: #808080;
  --elephant-white: #dedee5;
  /*--light-grey: #eceef0;*/
  --light-grey: #004e97;
  --border: #dee1e4;
  --background: #d2daff;
}
.faq-container{
  min-height: 80vh;
  padding-bottom: 3rem;
}
.faq-tab{
  /* background-color: red; */
  position: relative;
}
.faq-link{
  font-size: 24px;
  font-weight: 600;
  color: var(--text-black);
}
.faq-link:hover{
  color: var( --light-grey);
}
.tab-active-class{
  color: var(--dark-purple) !important;
}
.faq-tab::after{
  content: '';
  border-right: 2px solid var(--border);
  height: 110%    ;
  width: 2px;
  position: absolute;
  right: 0;
}

.acc-header button{
  font-size: 24px !important;
  font-weight: 600;
  color: var(--text-black);
  border-radius: 10px !important;
}
.accordion-button:not(.collapsed) {
  color: var(--dark-purple);
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 rgba(248, 123, 123, 0.13);
}

.accordion-item{
  border-radius: 10px;
}
.accordion-body{
  color: rgba(63, 63, 63, 0.5);
  font-weight: 500;
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  /* background-image: var(--bs-accordion-btn-icon); */
  background-image: url(../images/arrowdown.svg);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  margin-top: 9px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../images/arrowdown.svg);
  transform: var(--bs-accordion-btn-icon-transform);
  margin-top: -9px;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../images/arrowdown.svg);
  transform: rotate();
  margin-top: -9px;
}

@media screen and (max-width:1400px) {
  .acc-header button{
    font-size: 20px !important;
  }
  .faq-link{
    font-size: 22px;
  }
}
@media screen and (min-width:675px) and (max-width:1366px) {
  .title{
    font-size: 44px;
  }
  .contact-heading{
    font-size: 48px;
  }
}
@media screen and (max-width:1199px) {
  .faq-tab::after {
    content: '';
    border-bottom: 2px solid var(--border);
    height: 0;
    width: 95%;
    position: absolute;
    left: 2.5%;
    bottom: -10%;
  }
  .search-cls{
    width: 45% !important;
  }
}
@media screen and (max-width:760px) {
  .faq-link{
    font-size: 18px;
  }
  .acc-header button {
    font-size: 18px !important;
  }
}
@media screen and (max-width:520px) {
  .faq-link{
    font-size: 16px;
  }
  .acc-header button {
    font-size: 14px !important;
  }
  .accordion-button::after {
    scale:.7
  }
  .accordion-body{
    font-size: 14px;
  }
  .search-cls{
    width: 100% !important;
  }
}

