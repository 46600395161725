@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
:root {
    --text-black: #3F3F3F;
    --text-grey: #8F9AA5;
    --white: #ffffff;
    --primary: #091D48;
    --dark-purple: #091D48;
    --grey: #808080;
    --elephant-white: #DEDEE5;
    --light-grey: #ECEEF0;
    --light-grey: #004e97;
    --border: #DEE1E4;   
    --background: #d2daff;
}

.about-banner{
    min-height: 521px;
    /* background-color: red; */
    /* background: url(../images/about-bg.png); */
    background-size: cover;
    object-fit: contain;
    background-repeat: no-repeat;
    
}
.about-para{
    width: 65% !important;
}
.who-img-div{
    object-fit: contain;
    height: auto; 
    width: auto;
    /* max-width: 400px;
    max-height: 400px; */
}
.who-img-div>img{
    width: 100%;
    height: 100%;
    max-width: 500px;
    
}
.who-text>h4{
    font-size: 34px;
    color: #091D48;
    font-weight: 600;
}
.who-text>h1{
    font-size: 54px;
    color: var(--text-black);
    font-weight: 600;
}
.who-text>p{
    color: var(--text-black);
    font-weight: 400;
}
@media screen and (max-width:980px) {
    .who-text>h1{
        font-size: 40px;
    }
    .who-text>p{
        font-size: small;
    }
}
@media screen and (max-width:767px) {
    .who-img-div{
        justify-content: center !important;
    }
    .who-img-div>img{
        width: 40%;
    }
    .who-text>h1{
        width: 100% !important;
    }
}
@media screen and (max-width:674px){
    .about-banner{
        min-height: 291px;
    }
}
@media screen and (max-width:520px){
    .who-text>h1{
        font-size: 33px;
    }
    .who-img-div>img{
        width: 60%;
    }
}


/* our mission */
.om-img-div{
    max-height: 514px;
    object-fit: cover;
    /* height: auto;
    width: auto; */
}
.om-img-div>img{
    width: 100%;
    height: 100%;
    /* max-height: 514px; */
}

.om-text>h4{
    font-size: 34px;
    color: #091D48;
    font-weight: 600;
}
.om-text>h1{
    font-size: 44px;
    color: var(--text-black);
    font-weight: bold;
}
.om-text>p{
    color: var(--text-black);
    font-weight: 400;
}

/* our-vision */

.ov-div{
    margin-top: 100px !important;
}

@media screen and (min-width:1441px) {
    .om-text>h1{
        width: 100% !important;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .om-img-div>img{
        height: 70%;
    }
    .om-text>h4{
        font-size: 30px;
    }
    .om-text>h1{
        font-size: 28px;
        width: 100% !important;
    }
    .om-text>p{
        font-size: small;
    }
    .ov-div{
        margin-top: unset !important;
    }
}
@media screen and (max-width:767px) {
    .om-img-div>img{
        width: 80%;
        height: 80%;
        margin: auto;
    }
    .om-text{
        padding-top: 0 !important;
    }
    .om-text>h1{
        width: 100% !important;
        font-size: 40px;
    }
    .ov-div{
        margin-top: unset !important;
    }
}
@media screen and (max-width:520px) {
    .om-text>h4{
        font-size: 25px;
    }
    .om-text>h1{
        font-size: 25px;
    }
    .om-text>p{
        font-size: small;
    }
    .om-text{
        padding: 0 5% !important;
    }
    .ov-div{
        margin-top: 10px !important;
    }
}


/* Our team */

.ot-heading>h4{
    font-size: 34px;
    color: #091D48;
    font-weight: 600;
}
.ot-heading>h1{
    font-size: 24px;
    color: var(--text-black);
    font-weight: 600;
}

.team-bg{
    /* background-color: red; */
    background: linear-gradient(to bottom, #877eff, #b4d5ff);
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  max-height: 293px;
  max-width: 293px;
  position: relative;
  height: 293px;
  width: 293px;
  border-radius: 20px;
}
.team-bg>img{
    position: absolute;
    bottom: 0;
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.member>h3{
    font-size: 34px;
    font-weight: 600;
    color: #091D48;
}
.member>h4{
    font-size: 24px;
    font-weight: 600;
    color: var(--grey);
}
.member:hover{
    margin-top: -15px;
    scale: 1.01;
    transition: all ease-in-out .6s;
}

@media screen and (min-width:991px) and (max-width:1161px) {
    .team-bg{
        max-width: 250px;
    }
}
@media screen and (min-width:560px) and (max-width:1080px) {
    .team-bg{
        width: 243px;
        height: 243px;
    }
    .team-bg>img{
        height: 120%;
    }
}
@media screen and (max-width:1080px){
    .member>h3{
        font-size: 24px;
    }
    .member>h4{
        font-size: 18px;
    }
}
@media screen and (max-width:520px){
    .team-bg{
        width: 193px;
        height: 193px;
    }
    .team-bg>img{
        height: 120%;
    }
}
