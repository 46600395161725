@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
:root {
  --text-black: #3f3f3f;
  --text-grey: #8f9aa5;
  --white: #ffffff;
  --primary: #091d48;
  --dark-purple: #091d48;
  --grey: #808080;
  --elephant-white: #dedee5;
  --light-grey: #eceef0;
  --light-grey: #004e97;
  --border: #dee1e4;
  --background: #d2daff;
}
body,
html {
  background-color: var(--background) !important;
  font-family: "Montserrat" !important;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.nav_link {
  color: var(--text-black) !important;
  font-weight: 500 !important;
  margin-right: 2rem;
  text-decoration: none;
}
.nav_link:nth-last-child(1) {
  margin-right: 0;
}
.nav_link:hover {
  color: var(--dark-purple) !important;
  font-weight: 500 !important;
}
.nav {
  align-items: center;
}
.logoImg {
  width: auto;
  height: auto;
  object-fit: cover;
  max-width: 204px;
}
.loginBtn {
  border: 1px solid var(--dark-purple) !important;
  color: var(--dark-purple) !important;
  max-height: 36px;
}
.loginBtn:hover {
  background-color: var(--dark-purple) !important;
  color: var(--white) !important;
}
.downloadBtn {
  background-color: var(--dark-purple) !important;
  color: var(--white);
  border: none !important;
}

.header-active-class {
  color: var(--dark-purple) !important;
  position: relative;
  border-bottom: 2px solid var(--primary);
}
.navbar-toggler {
  border: none !important;
}
.navbar-toggler-icon {
  background-image: url("../images/menubar.svg") !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.navbar-fixed-top {
  z-index: 99;
  position: sticky;
  top: 0;
}
/* .no-scroll {
  overflow: hidden;
} */

/* Custom styles for Navbar collapse */
@media (max-width: 992px) {
  .navbar-collapse {
    position: fixed;

    /* left: -100%; */
    top: -100%;
    /* height: calc(100vh - 6%); */
    /* background-color: #93b8f5; */
    transition: all 1s ease;
    z-index: 1;
    /* padding: 2rem 0; */
    border-radius: 15px;
    max-width: 90%;
    margin: 0 auto;
  }

  .navbar-collapse.show {
    /* left: 50%; */
    /* transform: translateX(-50%); */
    top: calc(0% + 69px);
    transition: all .5s ease;

  }

  /* .navbar-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
  } */
}
.navbar-colorize {
  background-color: #6a83b8;
}

@media (max-width: 992px) {
  .navbar-collapse {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .navbar-collapse {
    position: static;
    height: auto;
    background-color: transparent;
    transition: none;
  }

  .navbar-nav {
    flex-direction: row;
  }
}

@media screen and (max-width: 991px) {
  .nav_link {
    margin-right: 0;
  }
  .navbar-nav {
    gap: 1.5rem;
    /* width: 75%; */
    border-radius: 24px;
    padding: 15px;
    margin: 0 auto;
    background-color: #6a83b8;
  }
}
@media screen and (max-width: 965px) {
  .logoImg {
    /* width: 190px !important;
        height: 43px !important; */
    max-width: 204px;
  }
  .nav_link {
    font-size: medium !important;
  }
  .loginBtn,
  .downloadBtn {
    font-size: medium !important;
  }
  .navbar-collapse {
    /* top: 6%; */
  }
}

/* --------------------------------------- */

/* Hero Banner */
.hero_heading {
  font-size: 5.1vw;
  color: var(--text-black);
  line-height: normal;
  width: 80%;
  font-weight: bold;
}
.hero_heading > span {
  color: var(--dark-purple);
}
.heroImg {
  height: 100%;
  width: 50%;
  object-fit: cover;
}
.heroImg > img {
  min-width: 30vw;
  max-width: 482px;
  /* max-height: 582px; */
}
.gitBtn {
  background-color: var(--dark-purple) !important;
  border: none !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  transition: 1s ease-in-out;
  overflow: hidden;
  /* transition: all 300ms ease; */
}
.gitBtn > a {
  text-decoration: none !important;
  color: unset !important;
}
.gitBtn:hover,
.downloadBtn:hover,
.loginBtn:hover {
  background-color: var(--dark-purple) !important;
  border: none !important;
  border-radius: 5px !important;
  font-weight: 500;
  box-shadow: 4px 4px 10px 2px rgba(0, 22, 121, 0.5);
  scale: 1.1;
  transition: all 300ms ease;
}

.heroPara {
  font-size: large;
  width: 75%;
}
@media screen and (min-width: 1080px) and (max-width: 1366px) {
  .hero_heading {
    font-size: 4.1vw;
  }
  .heroPara {
    font-size: medium;
  }
  .gitBtn {
    font-size: medium !important;
  }
  .heroImg > img {
    max-width: 432px;
  }
}
@media screen and (max-width: 992px) {
  .heroPara {
    font-size: medium;
  }
}
@media screen and (max-width: 960px) {
  .hero_heading {
    font-size: 4.1vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .gitBtn {
    font-size: medium !important;
  }
}
@media screen and (max-width: 768px) {
  .hero_heading {
    font-size: 6.1vw;
    color: var(--text-black);
    width: 100% !important;
  }
  .heroImg > img {
    min-width: 30vw;
    max-width: 252px;
    /* max-height: 582px; */
  }
  .heroPara {
    font-size: medium;
    width: 100% !important;
  }
}
@media screen and (max-width: 520px) {
  .gitBtn {
    font-size: small !important;
  }
}
@media screen and (max-width: 480px) {
  .heroPara {
    font-size: small;
    width: 100% !important;
  }
}

/* counter */
.counterBg{
    background-color: var(--dark-purple) !important;
}
.counter {
  /* padding: 20px 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--dark-purple);
  border-right: 2px solid #d9d9d9;
  color: var(--white);
}
.counter:nth-last-child(1) {
  border-right: 0;
}
.counter > h1 > span {
  color: #ffa000;
}
.counter p {
  margin-bottom: 0;
}

@media screen and (max-width: 575px) {
  .counter {
    border-right: 0;
  }
  .counterBg {
    background-color: unset !important;
  }
}

/* Main feature */

.feature-heading {
  text-align: center;
  font-size: 50px;
  color: var(--text-black);
  font-weight: bold;
}
.imageDiv img{
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.feature {
  min-height: 300px;
  object-fit: cover;
  border: 1px solid #e9e3fb;
  border-radius: 20px;
  max-width: 260px;
}
.feature:hover {
  background-color: #8a94ff78;
  transition: all 0.5s ease;
  box-shadow: 2px 3px 11px 2px #003eff54;
}
.feature > h6 {
    text-align: center;
    font-size: 19px;
}
@media screen and (min-width: 992px) and (max-width: 1119px) {
  .feature:nth-last-child(1) {
    margin-top: 20px !important;
    /* background-color: red !important; */
  }
}
@media screen and (max-width: 500px) {
  .feature-heading {
    font-size: 36px;
  }
  .feature {
    scale: 0.9;
  }
}

/* Banner */

.banner-bg {
  background-image: url(../images/banner-bg.png);
  /* width: 100vw; */
  height: auto;
  object-fit: cover;
  color: var(--white);
}
.banner-title > h2 {
  font-size: 52px;
  font-weight: bold;
}
.job-post > button {
  font-size: 30px;
  border-radius: 50px;
  max-height: 77px;
}
.jp-btn:hover {
  scale: 1.1;
  background-color: #dbdbdb !important;
  transition: all 0.5s ease-in-out;
  color: var(--text-black) !important;
  font-weight: 700;
  box-shadow: 4px 4px 10px 2px rgba(148, 150, 255, 0.5);
}

@media screen and (max-width: 500px) {
  .banner-title > h2 {
    font-size: 25px;
  }
  .banner-title > p {
    font-size: 11px;
  }
  .job-post > button {
    font-size: 18px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1044px) {
  .job-post > button {
    font-size: 24px;
  }
}

.jm {
  max-height: 50vh;
}
.object-div {
  object-fit: cover;
  /* width: ; */
}
.object-div > img {
  width: 100%;
  max-height: 422px;
  object-fit: contain;
}
.tabs > h2 {
  font-size: 52px;
  font-weight: 600;
  color: var(--text-black);
}
.tabs > h3 {
  font-size: 42px;
  font-weight: 400;
}
.tabbing {
  background-color: rgba(117, 50, 200, 0.3);
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 75%;
  position: relative;
  overflow: hidden;
  /* transition: all 1s ease-in; */
}
.tabbing::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 150%;
  left: -150%;
  top: 0;
  background-color: #b35ccb;
  z-index: 9;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.tabbing span {
  font-size: 32px;
  z-index: 99;
}
.tabbing:hover::before {
  left: 0;
  transition: all 0.7s ease-in;
  color: white;
}

@media screen and (min-width: 992px) and (max-width: 1344px) {
  .tabs > h2 {
    font-size: 38px;
  }
  .tabs > h3 {
    font-size: 29px;
  }
  .tabbing span {
    font-size: 24px;
  }
}
@media screen and (max-width: 540px) {
  .tabs > h2 {
    font-size: 31px;
  }
  .tabs > h3 {
    font-size: 22px;
  }
  .tabbing {
    width: 90% !important;
  }
  .tabbing span {
    font-size: 25px;
  }
}

.slider-div {
  position: relative;
}
.my_swiper {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: var(--background);

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet {
  background: #cdb8ee;
}
.swiper-button-prev,
.swiper-button-next {
  color: var(--text-black) !important;
}
.swiper-pagination-bullet-active {
  background: var(--dark-purple) !important;
}
@media screen and (max-width: 900px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 35px !important;
}
/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */

.slide-content {
  width: 90%;
}
.quote {
  height: 60px;
  width: 60px;
}
.quote > img {
  height: 100%;
  width: 100%;
}
.review-text {
  width: 70%;
}
.review-img {
  width: 20%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.review-img > img {
  width: 100%;
  height: auto;
}
.review-text > h3 {
  font-size: 38px;
  font-weight: 600;
  color: var(--text-black);
}
.review-text > h5 {
  font-size: 28px;
  font-weight: bold;
  color: var(--text-black);
}
.review-text > h5:nth-last-child(1) {
  font-size: 28px;
  font-weight: normal;
  color: var(--grey);
}

@media screen and (max-width: 1223px) {
  .review-text > h3 {
    font-size: 28px;
  }
  .review-text > h5 {
    font-size: 20px;
  }
  .review-text > h5:nth-last-child(1) {
    font-size: 20px;
  }
}

@media screen and (min-width: 580px) and (max-width: 811px) {
  .quote {
    width: 30px;
    height: 30px;
  }
  .review-text {
    width: 75%;
  }
  .review-text > h3 {
    font-size: 20px;
  }
  .review-text > h5 {
    font-size: 16px;
  }
  .review-text > h5:nth-last-child(1) {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .review-text > h3 {
    font-size: 16px;
  }
  .review-text > h5 {
    font-size: 14px;
  }
  .review-text > h5:nth-last-child(1) {
    font-size: 14px;
  }
  .quote {
    height: 30px;
    width: 30px;
  }
}

/* Download section */
.download-div {
  max-height: 500px;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
  padding: 2rem;
  background-color: var(--dark-purple);
}
.jm-div {
  padding: 7rem;
  border-radius: 100px;
  max-height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-row {
  padding: 2rem;
}
.download-text {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.download-text > h1 {
  font-size: 50px;
  font-weight: 600;
  color: var(--white);
}
.download-text > p {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
}
.store-btn > img {
  width: 30%;
}
.btn-download {
  background-color: #004e97;
  color: #ffffff;
  border-radius: 12.86px;
  width: 230px;
}
.btn-download:hover {
  /* Override any hover effects here */
  background-color: initial;
  color: initial;
  border-color: initial;
  box-shadow: none;
  background-color: #004e97;
  color: #ffffff;
}
.btn-download .download-btn-title {
  font-size: 16px;
  text-align: start;
}
.btn-download .download-btn-subtitle {
  font-size: 23px;
}
.btn-download > .right {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.img-fluid {
  height: 31px !important;
}
@media screen and (max-width: 1320px) {
  .btn-download .download-btn-title {
    font-size: 15px;
  }
  .btn-download .download-btn-subtitle {
    font-size: 21px;
  }
}
@media screen and (max-width: 1220px) {
  .store-btn {
    flex-wrap: wrap;
    gap: 5px;
  }
}
@media screen and (min-width: 1080px) and (max-width: 1366px) {
  .download-text > h1 {
    font-size: 40px;
  }
  .store-btn > img {
    width: 35%;
  }
}
@media screen and (max-width: 1278px) {
  .download-text > h1 {
    font-size: 35px;
  }
  .download-text > p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .jm-div {
    border-radius: 110px;
  }
  .jm-div > img {
    width: 80%;
  }
  .download-text > p {
    font-size: 12px;
  }
}

@media screen and (max-width: 986px) {
  .download-text > h1 {
    font-size: 27px;
  }
  .jm-div {
    padding: 6rem;
  }
  .bg-row {
    padding: 1rem;
  }
}
@media screen and (max-width: 720px) {
  .download-div {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 0px;
  }
  .jm-div {
    border-radius: 45px;
    padding: 4rem;
  }
  .download-text > h1 {
    font-size: 22px;
  }
  .download-text > p {
    font-size: 10px;
  }
}
@media screen and (min-width: 575px) and (max-width: 992px) {
  .store-btn > img {
    width: 40%;
  }
  .btn-download {
    min-width: 210px;
    width: 210px;
  }
  .btn-download .download-btn-subtitle {
    font-size: 19px;
  }
}
@media screen and (max-width: 575px) {
  .download-div {
    padding: 2rem;
    max-height: 1000px;
  }
  .btn-download {
    min-width: 200px;
    width: 200px;
  }
  .jm-div > img {
    width: 60%;
  }
  .download-text > h1 {
    font-size: 19px;
  }
  .store-btn > img {
    width: 40%;
  }
  .btn-download .download-btn-title {
    font-size: 12px;
  }
  .btn-download .download-btn-subtitle {
    font-size: 18px;
  }
}
@media screen and (min-width: 1800px) {
  .bg-row {
    padding: 3rem 2.5rem;
  }
}

/* Join us section */
.join-us-heading {
  font-size: 46px;
  font-weight: 300;
  margin-top: 6rem;
}
@media screen and (max-width: 980px) {
  .join-us-heading {
    font-size: 36px;
  }
}
@media screen and (max-width: 760px) {
  .join-us-heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 575px) {
  .join-us-heading {
    font-size: 25px;
    margin-top: 3rem;
  }
}

.footer-img {
  /* max-height: 354px; */
  object-fit: contain;
}
.footer-img > img {
  width: 100%;
  height: 100%;
}

/* Footer */
.footer {
  background-color: var(--dark-purple);
  /* width: 100vw; */
}
.footer-links {
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.footer-links span,
.dbt {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
}
.dbt > a {
  text-decoration: none;
  color: var(--white);
}
/* .footer-link span:hover,.dbt>a:hover{
    color: #F9FF00 !important;
} */

.footer-links span:nth-child(3):hover {
  color: var(--white) !important;
}
.footer-link {
  text-decoration: none;
}
.footer-active-class span {
  color: #f9ff00 !important;
}
.f-icons {
  height: 20px;
  width: 20px;
}
.f-icons:hover {
  color: #f9ff00 !important;
  transition: all 0.5s ease;
}
@media screen and (max-width: 1184px) {
  .footer-links span {
    font-size: 14px;
  }
  .dbt {
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1061px) {
  .footer-links span {
    font-size: 13px;
  }
  .dbt {
    font-size: 13px;
  }
}
@media screen and (max-width: 932px) {
  .footer-links span {
    font-size: 14px;
  }
  .dbt {
    font-size: 14px;
  }
}
@media screen and (max-width: 836px) {
  .footer-links span {
    font-size: 11px;
  }
  .dbt {
    font-size: 11px;
  }
}
@media screen and (max-width: 580px) {
  .footer-links {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .footer-img {
    display: flex;
    justify-content: center;
  }
  .footer-img > img {
    width: 100%;
    height: 150%;
    min-width: 150vw;
  }
}

.footer-link span:hover {
  color: yellow !important;
}
.dbt:hover {
  color: white !important;
}
.dbt a:hover {
  color: #f9ff00 !important;
}

a.tooltiplink {
  /* position: relative; */
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
/* a.tooltiplink:hover::after {
    content: attr(data-title);
    background-color: transparent;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    font-size: 10px;
    line-height: 14px;
    display: block;
    position: absolute;
    top: 98%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
} */
a.tooltiplink {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

a.tooltiplink:hover::after {
  content: attr(data-title);
  background-color: transparent;
  color: #fff;
  /* padding: 8px; */
  /* border-radius: 8px; */
  font-size: 7.5px;
  line-height: 14px;
  display: block;
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translateX(-50%);
  white-space: pre-wrap; /* Ensures text wraps within the tooltip */
  width: max-content; /* Ensures the width adjusts to content */
  max-width: 244px; /* Optional: Limit the maximum width */
  text-align: center; /* Optional: Center the text */
  z-index: 1;
}

@media screen and (max-width: 1363px) {
  .footer-links span,
  .dbt {
    font-size: 9px;
  }
}
